export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76')
];

export const server_loads = [0];

export const dictionary = {
		"/": [19],
		"/agentii/jobs": [20,[2]],
		"/agentii/real-estate": [21,[2]],
		"/arte-mestesuguri/arte": [22,[3]],
		"/arte-mestesuguri/cadouri": [23,[3]],
		"/arte-mestesuguri/diverse": [24,[3]],
		"/arte-mestesuguri/hand-made": [25,[3]],
		"/biserici": [26,[4]],
		"/comert/magazine": [27,[5]],
		"/comert/piete": [28,[5]],
		"/comert/produse": [29,[5]],
		"/consultanta-administrare/asigurari": [30,[6]],
		"/consultanta-administrare/avocat": [31,[6]],
		"/consultanta-administrare/consultanta": [32,[6]],
		"/consultanta-administrare/contabilitate": [33,[6]],
		"/consultanta-administrare/finante": [34,[6]],
		"/consultanta-administrare/notar": [35,[6]],
		"/consultanta-administrare/pr-publicitate": [36,[6]],
		"/consultanta-administrare/servicii-juridice": [37,[6]],
		"/consultanta-administrare/traduceri": [38,[6]],
		"/contact": [39,[7]],
		"/cookies": [40,[8]],
		"/despre-noi": [41,[9]],
		"/educatie-cultura/activitati-extra-curiculare": [42,[10]],
		"/educatie-cultura/ateliere": [43,[10]],
		"/educatie-cultura/cursuri": [44,[10]],
		"/educatie-cultura/scoli": [45,[10]],
		"/posts": [46],
		"/privacy": [47,[11]],
		"/reparatii-instalari/auto-moto-velo": [48,[12]],
		"/reparatii-instalari/constructii": [49,[12]],
		"/reparatii-instalari/diverse": [50,[12]],
		"/reparatii-instalari/electrocasnice-echipamente": [51,[12]],
		"/reparatii-instalari/electronice-calculatoare": [52,[12]],
		"/sanatate-ingrijire/_produse": [59,[13]],
		"/sanatate-ingrijire/beauty": [53,[13]],
		"/sanatate-ingrijire/clinici-spitale": [54,[13]],
		"/sanatate-ingrijire/diverse": [55,[13]],
		"/sanatate-ingrijire/medici": [56,[13]],
		"/sanatate-ingrijire/spa": [57,[13]],
		"/sanatate-ingrijire/zen": [58,[13]],
		"/servicii-domiciliu/babysitting": [61,[14]],
		"/servicii-domiciliu/cleaning": [62,[14]],
		"/servicii-evenimente/catering": [63,[15]],
		"/servicii-evenimente/decor": [64,[15]],
		"/servicii-evenimente/diverse": [65,[15]],
		"/servicii-evenimente/localuri": [66,[15]],
		"/servicii-evenimente/muzica": [67,[15]],
		"/servicii-evenimente/organizare-evenimente": [68,[15]],
		"/servicii-evenimente/photo-video": [69,[15]],
		"/servicii": [60],
		"/sport-divertisment/centre-agrement": [70,[16]],
		"/sport-divertisment/cluburi": [71,[16]],
		"/sport-divertisment/diverse": [72,[16]],
		"/test-component": [73,[17]],
		"/transportatori/colete": [74,[18]],
		"/transportatori/diverse": [75,[18]],
		"/transportatori/pasageri": [76,[18]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';